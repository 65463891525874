<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id&filter[system]=2&filter[privilege][gt]=8&filter[privilege][lt]=14"
    entity-name="身份"
    add-btn="新增身份"
    hide-show-detail
    show-row-num
    :deal-list="dealList"
    :before-update="beforeUpdate"
    :rules="rules"
    :columns="columns"
    :search-key-type="searchKeyType"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="手机号码">
        <a-input v-model="form.mobile" placeholder="输入手机号码查询" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="人员" prop="user_id">
        <select-book type="user" v-model="form.user_id"></select-book>
      </a-form-model-item>
      <a-form-model-item label="手机号码" prop="mobile">
        <a-input v-model="form.mobile" placeholder="输入手机号码" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item label="身份类别" prop="privilege">
        <a-select placeholder="选择身份类别" :options="catOptions" v-model="form.privilege" allowClear></a-select>
      </a-form-model-item>
    </template>
  </data-list>
</template>
<script>
import { catOptions } from "../../../common/hr/volunteer";
import { clone, isEmpty } from "../../../common/js/tool";
import { getSystemRoleName } from "../../../permission/subsystem";

export default {
  name: "RoleSet",
  data() {
    return {
      url: "/admin/user-role",
      searchKeyType: {
        mobile: function(form, key) {
          let value = form[key];
          let res = "";
          if (!isEmpty(value)) {
            res = `&filter[${key}][fis]=${value}`;
          }
          return res;
        },
      },
      columns: [
        {
          title: "姓名",
          dataIndex: "user_id",
          customRender: (text) => {
            return <open-data type="userName" openid={text[0]} />;
          },
        },
        { title: "手机号码", dataIndex: "mobile" },
        {
          title: "身份类别",
          dataIndex: "roleText",
        },
      ],
      rules: {
        user_id: [{ required: true, message: "请选择人员", trigger: "change" }],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: "^1[3-9]\\d{9}$", message: "请输入正确的手机号码" },
        ],
        privilege: [{ required: true, message: "请选择身份类别", trigger: "change" }],
      },
      catOptions: catOptions,
    };
  },
  computed: {
    dataList() {
      return this.$refs.dataList;
    },
  },
  methods: {
    dealList(list, fn) {
      list?.forEach((item) => {
        if (item.user_id) {
          item.user_id = JSON.parse(item.user_id);
        }
        item.roleText = getSystemRoleName(item.system, item.privilege);
      });
      fn(list);
    },
    beforeUpdate(form, fn) {
      let res = clone(form);
      if (!res.id) {
        res.system = 2;
      }
      res.user_id = JSON.stringify(res.user_id);
      fn(res);
    },
    updateList() {
      this.dataList.updateList();
    },
  },
};
</script>

<style lang="less" scoped></style>
